import {GiStarsStack} from 'react-icons/gi'
import {IoMdSchool} from 'react-icons/io'
import {MdVolunteerActivism} from 'react-icons/md'

const data = [
    {id: 1, icon: <GiStarsStack/>, title: 'Specialisation', desc: 'Game Development Serious Game Design'},
    {id: 2, icon: <IoMdSchool/>, title: 'Education', desc: 'Bachelor and Master'},
    {id: 3, icon: <MdVolunteerActivism/>, title: 'Volunteer', desc: 'Cooking for homeless people'}
    ]

    export default data;