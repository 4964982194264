import AboutImage from '../../Assets/about.jpg'
import CV from '../../Assets/CV2023.pdf'
import {HiDownload} from 'react-icons/hi'
import Card from '../../Components/card'
import data from './data'
import './about.css'

function About() {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__left">
          <div className="about__portrait">
            <img src={AboutImage} alt="About" />
          </div>
        </div>
        <div className="about__right">
          <h2>About Me</h2>
          <div className="about__cards">
            {
              data.map(item => (
                <Card key={item.id} className='about__card'>
                  <span className='about__card-icon'>{item.icon}</span>
                  <h5>{item.title}</h5>
                  <small>{item.desc}</small>
                </Card>
              ))
            }
          </div>
          <p>
            Working on software projects that benefits society has been my passion for a long time. 
            I specialize in <b>game development (Unity)</b> and <b>serious game design</b>, but I am also considerably exposed to <b>UX&UI</b>, <b>sound design</b> and <b>web development</b>.
            <br />
            <br />
            My <b>Bachelor Game Development degree</b> and <b>Master Game Technology degree</b> provided me with an exceptional foundation
            in the technical skills, social skills and creative vision necessary to thrive in the IT-industry. 
            <br />
            <br />
            Almost every Sunday I do volunteer work by <b>cooking for homeless people</b> in my city. I also keep an Instagram page up to date 
            with all my new invented and prepared cooking recipes. 
            In addition I enjoy boxing, making music and traveling in my spare time; 
          </p>
          <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
        </div> 
      </div>  
    </section>
  )
}

export default About