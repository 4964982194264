import {RiMentalHealthFill} from 'react-icons/ri'
import {FaGamepad} from 'react-icons/fa'
import {FaMobileAlt} from 'react-icons/fa'
import {MdOutlineDesignServices} from 'react-icons/md'
import {MdOutlineWeb} from 'react-icons/md'
import {BsFillCalendarDateFill} from 'react-icons/bs'


const data = [
    {id:1, icon: <RiMentalHealthFill/>, title: 'Serious game design', 
    desc: "My experience ranges from games that teach stress self-regulation, to gamified applications that teach children how to eat healthy. Designing games to find solutions for problems in society is one of my greatest passions ans skill"},
    {id:2, icon: <FaGamepad/>, title: 'Game development', 
    desc: "Unity has been my work environment for over 5 years in which I have created 2D, 3D, VR and AR games. During my education I learned about a wide range of game development skills such as: procedural generation, shader programming and inversed kinematics"},
    {id:3, icon: <FaMobileAlt/>, title: 'Mobile development', 
    desc: "Worked on React Native (XAML, Javascript and C#) and Native (Java) applications. For example, created an image-recognition app (React Native) for supermarkets to reduce the use of plastic wrappers."},
    {id:4, icon: <MdOutlineDesignServices/>, title: 'UI&UX design', 
    desc: "Skills aquired through creating web designs, game interfaces and app designs. Strong ability to test software and improve user experience. Passionate about creating engaging and memorable experiences. Dedicated to creating visually appealing and highly functional layouts."},
    {id:5, icon: <MdOutlineWeb/>, title: 'Front-End web development', 
    desc: "Freelanced as a front-end web developer for more than 5 years. I worked for several clients to great an experience suited to their needs. Experience in Wordpress, HTML, CSS and Javascript (React JS)"},
    {id:6, icon: <BsFillCalendarDateFill/>, title: 'Scrum and Kanban', 
    desc: "Very comfortable in SCRUM and Kanban methodologies, skilled in managing projects and workflows. Valuable asset in software development with ability to adapt and communicate methodologies to specific needs of team and project."}
]
export default data;