import { useThemeContext } from "../Context/theme-context"

const Backgroundcolor = ({className}) => {
  const {themeHandler} = useThemeContext();

  return (
    <button className={className} onClick={() => themeHandler(className)}></button>
  )
}

export default Backgroundcolor