import data from './data'
import Card from '../../Components/card'
import './services.css'

function Services() {
  return (
    <section id="services">
      <h2>My Services</h2>
      <p>I strive to provide you with quality services in all the areas listed below.</p>
      <div className="container services__container" data-aos="fade-up">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">{item.icon}</div>
              <div className="service__details">
                <h4>{item.title}</h4>
                  <p>{item.desc}</p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Services