import Image1 from '../../Assets/project1.jpg'
import Image2 from '../../Assets/project2.jpg'
import Image3 from '../../Assets/project3.jpg'
import Image4 from '../../Assets/project4.jpg'
import Image5 from '../../Assets/project5.jpg'
import Image6 from '../../Assets/project6.jpg'
import Image7 from '../../Assets/project7.jpg'
import Image8 from '../../Assets/project8.jpg'
import Image9 from '../../Assets/project9.jpg'
import Image10 from '../../Assets/project10.jpg'
import Image11 from '../../Assets/project11.jpg'
import Image12 from '../../Assets/project12.jpg'
import Image13 from '../../Assets/Project13.jpg'
import Image14 from'../../Assets/Project14.jpg'
import Image15 from'../../Assets/Project15.jpg'
import BscThesis from '../../Assets/BachelorScriptieWiandiVreeswijk.pdf'
import MscThesis from '../../Assets/MasterThesisWiandiVreeswijk.pdf'

const data = [
    {
        id: 1,
        category: 'Serious Games',
        image: Image1,
        title: "Memoria",
        desc: "Help Elena's grandma retrieve her precious memories from the past in Memoria! A compelling story-driven adventure through grandma's neighbourhood. Dive into unique 2D platformer challenges located in the mysterious past to collect grandma's memories.",
        link1: 'https://wiavre3.wixsite.com/my-site-1',
        link2: 'https://github.com/WiandiVreeswijk/Memoria',
        name1: "Website",
        name2: "Github"
    },
    {
        id: 2,
        category: 'Game Development',
        image: Image2,
        title: "Creating gameflow by utilising speech recognition",
        desc: "Endless runner is a game created for research purposes. To see if with serious game design & speech recognition technology an experience could be created. The goal of this study was that people could play games without using hands.",
        link1: 'https://summit-2021-sem2.game-lab.nl/2021/03/24/__trashed-2/',
        link2: 'https://github.com/WiandiVreeswijk/EndlessRunner_SpeechRecognition',
        name1: "Website",
        name2: "Github"
    },
    {
        id: 3,
        category: 'Game Development',
        image: Image3,
        title: "Explora",
        desc: "Created a 3D open world survival/exploration game with a professional team. Worked with voice actors to create voices of characters in the game. Composed orchestral music. Worked on game design and development in Unity",
        link1: 'https://www.youtube.com/watch?v=SpQfJCdu8ro',
        link2: "#",
        name1: "Trailer",
        name2: "no link"
    },
    {
        id: 4,
        category: 'Game Development',
        image: Image4,
        title: "Into the Crossfade",
        desc: "Pre-released a 2D action-puzzle platformer made in Unity called Into The Crossfade. Worked from game design to game development to game art & sound. Also executed numerous playtest sessions and utilized new learned marketing strategies.",
        link1: 'https://intothecrossfade.jouwweb.nl/',
        link2: 'https://github.com/WiandiVreeswijk/ITTCHDRP',
        name1: "Website",
        name2: "Github"
    },
    {
        id: 5,
        category: 'Game Development',
        image: Image5,
        title: "Escape from Valhalla",
        desc: "Lead the sound design division of a 50 man team. The team worked on a 2D/3D platformer. Mainly worked on SFX, music composition and implementation of sound into Unity.",
        link1: 'https://www.youtube.com/watch?v=KoKaDGbISuQ&ab_channel=Jodelahithit',
        link2: 'https://soundcloud.com/wiandi-vreeswijk/escape-from-valhalla-ost-traininggrounds',
        name1: "Trailer",
        name2: "Soundtrack"
    },
    {
        id: 6,
        category: 'Mobile Development',
        image: Image6,
        title: "Snaai",
        desc: "Studied the healthiness of the eating habits of an average student. Based on the study, developed a react native mobile application (Xamarin) that would influence the eating habits of a student.",
        link1: 'https://github.com/WiandiVreeswijk/Snaai',
        link2: 'https://github.com/WiandiVreeswijk/Snaai/blob/main/com.companyname.SnaaiV1.zip',
        name1: "GitHub",
        name2: "APK"
    },
    {
        id: 7,
        category: 'Mobile Development',
        image: Image7,
        title: "SPAR object detection",
        desc: "Worked on an image recognition mobile application (Java & Python). The application is used to scan individual products in a supermarket without the need of a barcode. The goal of this application was to decrease plastic pollution",
        link1: 'https://www.youtube.com/watch?v=KnlZWrzcnLY',
        link2: 'https://github.com/WiandiVreeswijk/SocialBrothersTensorFlow',
        name1: "Demo",
        name2: "Github"
    },
    {
        id: 8,
        category: 'Web Development',
        image: Image8,
        title: "LSMO website",
        desc: "Designed and developed a Wordpress website for LSMO. An ongoing collaboration as I am still doing their content creation.",
        link1: 'https://lsmo.nl/',
        link2: '#',
        name1: "Website",
        name2: "No link"
    },
    {
        id: 9,
        category: 'Web Development',
        image: Image9,
        title: "VerenigingvanKumpulans website",
        desc: "Designed and developed a Wordpress website for the Vereniging van Kumpulans in Nederland. An ongoing collaboration as I am still doing their content creation.",
        link1: 'https://verenigingvankumpulans.nl/',
        link2: '#',
        name1: "website",
        name2: 'No link'
    },
    {
        id: 10,
        category: 'Game Development',
        image: Image10,
        title: "Nuclear Edge",
        desc: "Worked in a team on the Nuclear Edge. I worked on the multiplayer element (Photon Unity), level design, technical art, sound and playtesting.",
        link1: 'https://hans-meulblok.itch.io/nuclear-edge',
        link2: 'https://www.youtube.com/watch?v=Z-x_ieq-DNY',
        name1: "Website",
        name2: "Trailer"
    },
    {
        id: 11,
        category: 'Serious Games',
        image: Image11,
        title: "Chef Koochooloo",
        desc: "Recruiting; Implementing UI & UX design; implementing gamification and playful design; Web application development in Haxe and Unity WebGL. Responsibility to recruit developers and designers for Chef Koochooloo to create a new version of the web application. Improved performance of the existing Haxe application. Aided in improving the new design by implementing game design theory and playful UX design.",
        link1: 'https://chefkoochooloo.com/',
        link2: 'https://software.chefkoochooloo.com/#/auth/login',
        name1: "Website",
        name2: "Web Application"
    },
    {
        id: 12,
        category: 'Serious Games',
        image: Image12,
        title: "ByCatch",
        desc: "By means of applied serious game design, the goal is to generate interest in the various topics related to the welfare of coastal and marine animals!",
        link1: 'https://wiandivreeswijk.itch.io/bycatch',
        link2: 'https://github.com/WiandiVreeswijk/ByCatch',
        name1: "Website",
        name2: "Github"
    },
    {
        id: 13,
        category: 'Web Development',
        image: Image13,
        title: "SOMNL Website",
        desc: "Designed and developed a Wordpress website for SOMNL. The website launched on the 13th of June 2023. An ongoing collaboration as I will be doing their content creation",
        link1: 'https://somnl.nu/',
        link2: '#',
        name1: "Website",
        name2: "No Link"
    },
    {
        id: 14,
        category: 'Research',
        image: Image14,
        title: "Bachelor thesis: the simulation of sound propagation in video games",
        desc: "This thesis was written in the context of my graduation from the HBO-ICT Game Development course at the Hogeschool van Amsterdam and commissioned by internship company WAEM game studios. From September 2021 to April 2022 I have been busy with research and writing the thesis.",
        link1: BscThesis,
        link2: 'https://wiavre3.wixsite.com/my-site-1',
        name1: "Thesis",
        name2: "Graduation product"
    }
    ,
    {
        id: 15,
        category: 'Research',
        image: Image15,
        title: "Master thesis: Stress self-regulation: a game-based mobile diaphragmatic breathing exercise",
        desc: "This thesis was written in the context of my graduation from the Master Game Technology course at the Breda University of Applied Sciences and supervised by Prof. Dr. Igor Mayer. From September 2022 to July 2023 I have been busy with research and writing the thesis.",
        link1: MscThesis,
        link2: 'https://wiavre3.wixsite.com/my-site-1',
        name1: "Thesis",
        name2: "Breeze 2"
    }
    
]


export default data