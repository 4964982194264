const data = [
        {
        id: 1,
        quote: "Wiandi Vreeswijk has proven himself to be an intelligent student, who puts time and energy in the tasks ahead.",
        name: 'Reggie Schildmeijer',
        profession: 'Lecturer Game Development'
        },
        {
        id: 2,
        quote: "From my perspective, Wiandi is a very smart and open-minded person, and he could certainly be a great culture fit in most Silicon Valley companies. I truly believe he will have a bright future after this internship.",
        name: 'Shanni Liu',
        profession: 'Instructional designer'
        },
        {
        id: 3,
        quote: "Involves others in his work. Takes charge when needed. Always speaks clearly about what he thinks and gives his input when needed.",
        name: 'Hans Meulblok',
        profession: 'Game developer'
        },
        {
        id: 4,
        quote: "Wiandi is very self starting, autonomous.Highly active in gathering feedback including external sources to help shape the project in an effective manner.",
        name: 'Igor Mayer',
        profession: 'Professor at Tilburg School of Social and Behavorial Sciences'
        },
        {
        id: 5,
        quote: "Incredible how much projects you have been working on. Wiandi is a natural at presenting and comes across as very professional",
        name: 'Danny Kroonen',
        profession: 'Technical lead at Innovam'
        },
        {
        id: 6,
        quote: "It was a pleasure to guide Wiandi and it's amazing how well he handles critical feedback! He proves himself to be a great sounddesigner and composer. ",
        name: 'Bart Dijkman',
        profession: 'Composer, producer & sound-designer for media since 1985'
        },
        {
        id: 7,
        quote: "Wiandi has thought carefully about how different systems work together or should be separate units. Wiandi also makes good use of external plugins or software as we expect from a professional.",
        name: 'Dick Heinhuis',
        profession: 'Lecturer Business management'
        }
    ]



    export default data