const data = [
    {
      id: 1,
      question: "What are you working on right now?",
      answer: "I finished my master thesis in June 2023 and submitted a shortpaper for publishing on JMIR.  Now that I graduated, I can focus on my freelance projects in July and August. On the 14th of August I will start as a junior Unity Developer at FutureWhiz. I can honestly say that I am super excited and can't wait to start working on one of their projects: Squla"
    },
    {
      id: 2,
      question: "Where do you see yourself in ten years?",
      answer: "I see myself having an impact on society by creating meaningful software. I value team bonding and growth, so I hope I will be part of a great team by then where teamplay and building effective solutions are priorities."
    },
    {
      id: 3,
      question: "What did you research during your master programme and what was the goal?",
      answer: "The research aims to improve stress self-regulation among younger adults by evaluating the effectiveness of deep breathing exercises embedded in video games."
    },
    {
      id: 4,
      question: "How much do you charge for an average website as a freelancer?",
      answer: "It depends on the size of the website. I ask less for a small one-page portfolio than for bigger websites that are meant for organisations and companies. Please contact me if you want a custom website build and we can come to a reasonable price!"
    },
    {
      id: 5,
      question: "What are your work related weaknesses? And how do you work on them to improve?",
      answer: "I sometimes find it hard to balance work and personal responsibilities. I try to solve this by scheduling fixed days when I invest time and love in my family, girlfriend and friends. Even as a freelancer, I always work inside workhours (9:00 - 17:00) and in the library to seperate my worklife from my private life."
    },
    {
      id: 6,
      question: "How are your soft skills? And how do you develop these?",
      answer: "My soft skills enable me to interact effectively with others in the workplace and in life. They include communication skills, teamwork, problem-solving, adaptability, and leadership skills, among others. I trained myself mostly in these by doing lots of volunteering work and by working in the kitchen as a restaurant chef, among other things. These activities trained me to work with lots of different people and improve my communication to both my colleagues and my customers. "
    }
  ]

  export default data