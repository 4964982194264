import { useEffect } from 'react'
import HeaderImage from '../../Assets/header.jpg'
import data from './data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'

function Header() {
  useEffect(() =>{
    AOS.init({duration:2000})
  }, [])

  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="fade-in">
          <img src={HeaderImage} alt="Header Portrait"/>
        </div>
        <h3 data-aos="fade-in">Wiandi Vreeswijk</h3>
        <p data-aos="fade-in">
          As a serious game designer, I seek inspiration through a wide range of external sources and experiences. 
          My passion lies in sound design, and I believe that constantly moving forward is the key to 
          discovering new and exciting things.
        </p>
        <div className="header__cta">
          <a href="#contact" className='btn primary'>Let's talk</a>
          <a href="#portfolio" className='btn light'>My work</a>
        </div>
        <div className="header__socials" data-aos="fade-right">
          {
            data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header