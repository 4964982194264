import {AiFillGithub} from 'react-icons/ai'
import { AiOutlineLinkedin } from 'react-icons/ai'
import { AiOutlineFacebook } from 'react-icons/ai'
import { AiOutlineInstagram } from 'react-icons/ai'

const data = [
    {id: 1, link: 'https://www.linkedin.com/in/wiandi-v-93746b10b/', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://github.com/WiandiVreeswijk', icon: <AiFillGithub/>},
    {id: 3, link: 'https://www.facebook.com/wiandi.vreeswijk', icon: <AiOutlineFacebook/>},
    {id: 4, link: 'https://www.instagram.com/chef_wia/', icon: <AiOutlineInstagram/>}
]

export default data
