// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";


// import required modules
import { Pagination } from "swiper";

import data from './data'
import Testimonial from "./testimonial";
import './testimonials.css'

function Testimonials() {
  return (
    <section id="testimonials">
      <h2>What people say about me</h2>
      <p>These are unbiased testmonials from people I have worked with</p>
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          breakpoints={{
            601: {slidesPerView: 2},
            1025: {slidesPerView: 3}
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={true}
          className="mySwiper"
        >
          {
            data.map(item =>(
              <SwiperSlide key={item.id}>
                <Testimonial testimonial={item}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  )
}

export default Testimonials